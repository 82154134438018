<template>
  <Questions/>
</template>

<script>
import Questions from '@/components/Questions/Editor/Editor'

export default {
  components: {
    Questions
  }
}
</script>

<style>

</style>